import React, { useState } from 'react'
import cross_exit from "../assets/cross_exit.svg"

function AgregarProducto({show, setShow}) {

    const [newProduct, setNewProduct] = useState("")
    const [stock, setStock] = useState(0)
    const [monto, setMonto] = useState(0)


    async function handleProductSubmit(e){
        e.preventDefault();
        setShow(!show)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const formCategoryData = { price:monto, 
                                   name:newProduct, 
                                   amount:stock,
                                   idWallet:Number(window.localStorage.getItem("walletId"))}
        
        console.log(formCategoryData);
  
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/new/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: JSON.stringify(formCategoryData)
          });
          
          if (response.status === 201) {
            // setLoader(false)
            console.log('Form submitted successfully');
            const data = await response.json();
            console.log(data);
            window.location.reload()
            } else {
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
          } catch (error) {
            console.error('Error:', error);
          }
      }

  return (
    <div className='add_category_container'>
    <form onSubmit={handleProductSubmit}>
        <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
        <label htmlFor="nuevoProducto">Nombre de Producto</label>
        <input 
            type="text"
            id='nuevoProducto'
            placeholder='Nombre de producto...'
            required
            value={newProduct}
            onChange={(e) => setNewProduct(e.target.value)}
        />
        <label htmlFor="nuevoStock">Stock Actual</label>
        <input 
            type="number"
            id='nuevoStock'
            placeholder='xxxxxx'
            min={0}
            required
            value={stock}
            onChange={(e) => setStock(e.target.value)}
        />
        <label htmlFor="nuevoMonto">Monto</label>
        <input 
            type="number"
            id='nuevoMonto'
            placeholder='xxxxxx'
            min={0}
            required
            value={monto}
            onChange={(e) => setMonto(e.target.value)}
        />
        <button type='submit' >Crear</button>
    </form>
</div>
  )
}

export default AgregarProducto
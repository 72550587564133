import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom'
import "../styles/Categorias.scss"
import add_cat from "../assets/add-cat.svg"
import cross_exit from "../assets/cross_exit.svg";
import Loading from './Loading'
import TablaTransacciones from './TablaTransacciones'
import AgregarComentario from './AgregarComentario'
import AgregarProducto from './AgregarProducto'
import AgregarProdTransac from './AgregarProdTransac'
import TablaTransProd from './TablaTransProd'
import RowProductos from './RowProductos'
import ModificarProducto from './ModificarProducto'

function CategoriasProductos() {

    const [showAddCategory, setShowAddCategory] = useState(false)
    const [apiUrl, setApiUrl] = useState("")
  
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showTransfer, setShowTransfer] = useState(false)
    const [showModifyCategory, setShowModifyCategory] = useState(false)
    const [showAddProdTransac, setShowAddProdTransac] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [modifyId, setModifyId] = useState(null)
    const [comments, setComments] = useState([])
    const [showDate, setShowDate] = useState(false)
    const [ifShared, setIfShared] = useState(false)
    const [walletForTransac, setWalletForTransac] = useState(0)
    const [allMonths, setAllMonths] = useState([])
  
  
  
    const [categoryId, setCategoryId] = useState(0);
    const [productos, setProductos] = useState([]);
    const [sharedCategorias, setSharedCategorias] = useState([]);
    const [transacciones, setTransacciones] = useState([]);
    const [transaccionesPendientes, setTransaccionesPendientes] = useState([]);
    const [sharedTransacciones, setSharedTransacciones] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activeIngresos, setActiveIngresos] = useState(true);
    const [activeEgresos, setActiveEgresos] = useState(false);
    // const [pendientes, setPendientes] = useState(false);
    const [showContra, setShowContra] = useState(false)
    const [passwordAction, setPasswordAction] = useState("")
    const [months, setMonths] = useState("")

    // TRAE TODOS LOS PRODUCTOS
    useEffect(() => {
        if(window.localStorage.getItem("walletId")){
          setLoader(true)
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          .then(data => console.log(data))
          .finally(setLoader(false))
        } else {
    
            setLoader(true)
            const TOKEN = window.localStorage.getItem("loggedToken")
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/categories/`;
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            };
        
            fetch(url,{ headers, withCredentials:true })
            .then(response => response.json())
            .then(data => setProductos(data))
            .finally(setLoader(false))
        
      }
    
      },[]);

    // TRAE COMPARTIDOS
      useEffect(() => {
        setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => sharedDatos(data))
        .finally(setLoader(false))
    
      },[]);
    
    // TRAE MESES
      useEffect(() => {
        // setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        // .then(data => setPrefCurrency(data.pref_currency))
        // .then(data => console.log(data))
        .then(data => {
          setAllMonths(data.months);
      })
        // .finally(        BringDatos())
      },[])
      // TRANSAC COMPARTIDAS
      useEffect(() => {
        setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => sharedTransac(data))
        .finally(setLoader(false))
        // eslint-disable-next-line
      },[]); 
    
      async function sharedDatos(compartidos){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
        // console.log(compartidos);
      
        if(compartidos.length === 0){
          setSharedCategorias([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/share/cat/${compartidos[0].id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  // body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setSharedCategorias(data);
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
    
        async function sharedTransac(cuentas){
    
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/bywal/${cuentas[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    pruebaCuentas(data);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
        async function pruebaCuentas(filtrados){
    
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          // const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(compartidos);
        
          if(filtrados.length === 0){
            console.log([])
          } else {
            setSharedTransacciones(filtrados)
              // try {
              //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/`, {
              //       method: 'GET',
              //       headers: {
              //         'Content-Type': 'application/json',
              //         'Authorization': `Token ${JSON.parse(TOKEN)}`
              //       },
              //       withCredentials:true,
              //       // body: JSON.stringify(formData),
              //     });
                  
              //     if (response.status === 200) {
              //       const data = await response.json();
              //       const perfilPrincipal = data.filter(user => user.id === Number(PROFILE_ID))
              //       setSharedTransacciones(filtrados.filter(cuentas => cuentas.name === perfilPrincipal[0].name));
                    
              //       } else {
              //         console.error('Error submitting form');
              //       }
              //     } catch (error) {
              //       console.error('Error:', error);
              //     }
                }
          }
    
      // useEffect(() => {
      //   setLoader(true)
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/cat/23/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => setSharedCategorias(data))
      //   .finally(setLoader(false))
    
      // },[]);
    
      // useEffect(() => {
    
      //   // setLoader(true)
      //   const PROFILE_ID = window.localStorage.getItem("profileId")
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/transactions/byprof/${PROFILE_ID}/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => setTransacciones(data.transactions.sort((a, b) => new Date(a.date) - new Date(b.date))))
      //   // .finally(setLoader(false))
    
      // },[]);
    
      useEffect(() => {
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => setMonths(data.months[data.months.length-1]))
    },[])
    
      useEffect(() => {
        // setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        // .then(data => setPrefCurrency(data.pref_currency))
        // .then(data => console.log(data))
        .then(data => {
            if(data.months.length === 0){
                BringDatos(0)
            } else {
                BringDatos(Number(window.localStorage.getItem("monthId")))
                BringDatosPendientes(Number(window.localStorage.getItem("monthId")))
            }
        })
      },[])
    
      async function HandleMonth(e){
        const MONTH_ID = e.target.value;
            
        if(Number(MONTH_ID) === months.id){
          window.localStorage.removeItem("prevMonth")
          window.localStorage.removeItem("aproved")
        } else {
          window.localStorage.setItem("prevMonth", true)
        }
        window.localStorage.setItem("monthId", MONTH_ID)
        window.location.reload()
      }
    
      async function BringDatos(id){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
        const WALLET_ID = window.localStorage.getItem("walletId")
      
        const formData = {idMonth:id , idWallet:Number(WALLET_ID)}
        // console.log(formData);
      
        if(id === 0){
          setTransacciones([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
    
        async function BringDatosPendientes(id){
    
          const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
        
          const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(formData);
        
          if(id === 0){
            setTransaccionesPendientes([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/pending/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    setTransaccionesPendientes(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
      function HandleTransactionClick1(){
        setActiveIngresos(true)
        setActiveEgresos(false)
      }
      function HandleTransactionClick2(){
        setActiveIngresos(false)
        setActiveEgresos(true)
      }



  return (
    <>
    <Header />
    <Sidebar
      setShow={setShowContra} 
      setPasswordAction={setPasswordAction}
    />
    <div className='cat_container'>
        {/* <button onClick={DescargaArchivo}>Descargar transacciones</button> */}
        <div className='add_cat_container'>
          <button className='add_category' onClick={() => setShowAddCategory(!showAddCategory)}><img src={add_cat} alt="add category" />Agregar Producto</button>
          {allMonths.length === 0 
              ? <p>Mes visualizado <span>Ninguno</span></p>
              : <p>Mes visualizado <select className="month_selector" onChange={HandleMonth}>{allMonths?.map((month) => (
                  month.id === Number(window.localStorage.getItem("monthId")) 
                    ? <option className="option_month" selected key={month.id} value={month.id}>{month.name}</option>
                    : <option className="option_month" key={month.id} value={month.id}>{month.name}</option>
                  
                  
              ))}</select></p>
          }
        </div>
        <div className='cat_title_container'>
          <p className='cat_title_1'>Productos</p>
        </div>
          <div className='table_header'>
            <p className='header_title1'>Fecha</p>
            <p className='header_title2'>Nombre Producto</p>
            <p className='header_title3'>Movimientos Totales</p>
            <span></span>
          </div>
        <div className='cat_table'>
          <ul>
            {productos?.map((e) => (
              <li key={e.id}>
                <RowProductos
                  walletId={e.idWallet}
                  dueñoId={e.user}
                  categorias={productos} 
                  cambioCategorias={setProductos} 
                  nombreCat={e.name} 
                  identificador={e.id} 
                  showModify={showModifyCategory}
                  setShowModify={setShowModifyCategory}
                  setModifyId={setModifyId}
                  showAddTransaction={showAddProdTransac}
                  setShowAddTransaction={setShowAddProdTransac}
                  setCategoryId={setCategoryId}
                  showConfirmation={showConfirmation}
                  setShowConfirmation={setShowConfirmation}
                  setApiUrl={setApiUrl}
                  compartida={false}
                  setIfShared={setIfShared}
                  setShowTransfer={setShowTransfer}
                  setWalletForTransac={setWalletForTransac}
                />
              </li>
            ))}

          </ul>
        </div>
        {/* <button className='add_category transac_btn' onClick={() => setShowAddTransac(!showAddTransac)}><img src={add_cat} alt="add category" />Transacciones</button> */}
        <div className='cat_title_container'>
          <p onClick={HandleTransactionClick1} className={activeIngresos ? 'cat_egresos' : 'cat_ingresos'}>Ingresos</p>
          <p onClick={HandleTransactionClick2} className={activeEgresos ? 'cat_egresos' : 'cat_ingresos'}>Disponibles</p>
        </div>
        {/* SI ACTIVEINGRESOS ES TRUE FILTRAR LA TABLA POR UNOS U OTROS */}
          <div className='table_header2'>
              <p className='header_title1'>Fecha</p>
              {activeEgresos
                ? <p className='header_title3'>Producto</p>
                : <p className='header_title3'>Producto</p>
              }
              
              <p className='header_title_2'>Precio individual</p>
              <p className='header_title3'>Cantidad</p>
              <p className='header_title3'>TOTAL</p>
              <span></span>
          </div>
        <div className='cat_table'>
          {activeIngresos 
            ? <ul>
                {transacciones?.map((transac) => (
                  <TablaTransProd
                    key={transac.id}
                    date={transac.date} 
                    walletId={transac.idWallet}
                    clave={transac.name}
                    comments={transac.comments}
                    profile_name={transac.product_price}
                    currAmount={transac.amount}
                    idCategory={transac.idCategory}  
                    categorias={productos}
                    identificador={transac.id}
                    showDate={showDate}
                    setShowDate={setShowDate}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    showComments={showComments}
                    setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    setCategoryId={setCategoryId}
                    setComments={setComments}
                    transactions={transacciones}
                    withCategory={true}
                    pendiente={false}
                  />
                ))}
                {sharedTransacciones?.filter(transac => transac.currAmount > 0).map((transac) => (
                  <TablaTransacciones 
                    key={transac.id}
                    transacName={transac.category_name}
                    date={transac.date} 
                    walletId={transac.idWallet}
                    clave={transac.clave}
                    comments={transac.comments}
                    profile_name={transac.product_price}
                    currAmount={transac.amount}
                    idCategory={transac.idCategory}  
                    categorias={productos}
                    identificador={transac.id}
                    showDate={showDate}
                    setShowDate={setShowDate}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    showComments={showComments}
                    setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    setCategoryId={setCategoryId}
                    setComments={setComments}
                    transactions={sharedTransacciones}
                    withCategory={false}
                    pendiente={false}
                  />
                ))}
              </ul>
          : activeEgresos ? <ul>
            {productos?.map((prod) => (
              <TablaTransProd
                key={prod.id}
                date={prod.date} 
                walletId={prod.idWallet}
                clave={prod.name}
                comments={prod.comments}
                profile_name={prod.product_price}
                currAmount={prod.amount}
                idCategory={prod.idCategory} 
                categorias={productos}
                identificador={prod.id} 
                showDate={showDate}
                setShowDate={setShowDate}
                showConfirmation={showConfirmation}
                setShowConfirmation={setShowConfirmation}
                showComments={showComments}
                setShowComments={setShowComments}
                setApiUrl={setApiUrl}
                setComments={setComments}
                setCategoryId={setCategoryId}
                transactions={transacciones}
                withCategory={true}
                pendiente={false}

              />
            ))}
            {sharedTransacciones?.filter(transac => transac.currAmount < 0).map((transac) => (
              <TablaTransProd
                key={transac.id}
                walletId={transac.idWallet}
                date={transac.date} 
                transacName={transac.category_name}
                clave={transac.clave}
                comments={transac.comments}
                profile_name={transac.name}
                currAmount={transac.amount}
                idCategory={transac.idCategory} 
                categorias={productos}
                identificador={transac.id} 
                showDate={showDate}
                setShowDate={setShowDate}
                showConfirmation={showConfirmation}
                setShowConfirmation={setShowConfirmation}
                showComments={showComments}
                setShowComments={setShowComments}
                setApiUrl={setApiUrl}
                setComments={setComments}
                setCategoryId={setCategoryId}
                transactions={sharedTransacciones}
                withCategory={false}
                pendiente={false}
              />
            ))}
          </ul>
          : transaccionesPendientes?.map((transac) => (
            <TablaTransacciones 
                key={transac.id}
                date={transac.date} 
                walletId={transac.idWallet}
                clave={transac.clave}
                comments={transac.comments}
                profile_name={transac.user_name}
                currAmount={transac.amount}
                idCategory={transac.idCategory} 
                categorias={productos}
                identificador={transac.id} 
                showDate={showDate}
                setShowDate={setShowDate}
                showConfirmation={showConfirmation}
                setShowConfirmation={setShowConfirmation}
                showComments={showComments}
                setShowComments={setShowComments}
                setApiUrl={setApiUrl}
                setComments={setComments}
                setCategoryId={setCategoryId}
                transactions={transacciones}
                withCategory={true}
                pendiente={true}
              />
          ))} 
        </div>
    </div>
    {showAddCategory 
      ? <AgregarProducto
          setShow={setShowAddCategory}
          show={showAddCategory} 
        /> 
      : ""
    }
    {showAddProdTransac 
      ? <AgregarProdTransac
          setShow={setShowAddProdTransac}
          show={showAddProdTransac} 
          categoryId={categoryId}
          ifShared={ifShared}
          walletForTransac={walletForTransac}
        /> 
      : ""
    }
    {showModifyCategory 
      ? <ModificarProducto
          setShow={setShowModifyCategory}
          show={showModifyCategory} 
          modifyId={modifyId }
        /> 
      : ""
    }
    
    {showConfirmation
      ? <ConfirmDelete show={showConfirmation} setShow={setShowConfirmation} identificador={categoryId} apiUrl={apiUrl} />
      : ""
    }
    {showDate
      ? <ChangeDate show={showDate} setShow={setShowDate} identificador={categoryId} />
      : ""
    }
    {showComments
      ? <AgregarComentario
          show={showComments}   
          setShow={setShowComments} 
          identificador={categoryId}  
          comments={comments}
        />
      : ""
    }
    {showTransfer
      ? <TransferCat
          show={showComments}   
          setShow={setShowTransfer} 
          identificador={categoryId} 
          categories={productos}
          sharedCategories={sharedCategorias} 
        />
      : ""
    }
    { loader 
      ? <Loading /> 
      : ""
    }
    {/* {showContra
      ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    } */}
    {window.localStorage.getItem("prevMonth") && !window.localStorage.getItem("aproved")
      ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    }
    
</>
  )
}

export default CategoriasProductos

function ConfirmDelete({show, setShow, identificador, apiUrl}) {

    const TOKEN = window.localStorage.getItem("loggedToken")
  
    async function deleteRow(){
      setShow(!show)
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/${apiUrl}del/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Deleted successfully');
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className="confirmacion_exitoso_container">
          <div className="conf_subcontainer">
              <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
              <h2>{`¿Seguro que desea eliminar este elemento?`}</h2>
              <div className='flex_conf'>
                <button className='confirm_delete' onClick={deleteRow}>Si</button>
                <button className='deny_delete' onClick={() => setShow(!show)}>No</button>
              </div>
          </div>
      </div>
    )
  }
  
  function ChangeDate({show, setShow, identificador}) {
  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const [date, setDate] = useState("")
  
    async function UpdateDate(){
      setShow(!show)
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/date/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify({date:date})
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Changed successfully');
          // console.log(response);
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className="confirmacion_date_container">
          <div className="conf_subcontainer">
              <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
              <form onSubmit={UpdateDate}>
                <label>Nueva fecha:</label>
                {/* <span>Recuerde de usar "-" entre los números.</span> */}
                <input type="date"
                  required
                  placeholder='yyyy-mm-dd'
                  value={date}
                  onChange={(e) => {setDate(e.target.value)}}
                  // max={new Date().toISOString().split('T')[0]}
                />
                <button type='submit'>Cambiar</button>
              </form>
          </div>
      </div>
    )
  }
  
  function TransferCat({show, setShow, identificador, sharedCategories, categories}) {
  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const [transferAmount, setTransferAmount] = useState("")
    const [prefCat, setPrefCat] = useState(0)
    const [activeIngresos, setActiveIngresos] = useState(true);
    const [activeEgresos, setActiveEgresos] = useState(false);
  
  
    function handleCatChange(e){
      const selectedWallet = e.target.options[e.target.selectedIndex];
      const id = selectedWallet.getAttribute('value');
      setPrefCat(id);
  }
  
  function HandleMontoChange(e){
    if(isNaN(e.target.value) && e.target.value.length < 3){
      setTransferAmount(0)
    } else {
      const inputValue = e.target.value;
      const formattedNumber = Number(inputValue.replace(/,/g,"")).toLocaleString('es-MX');
      setTransferAmount(formattedNumber)
    }
  }
  
  const PROFILE_ID = window.localStorage.getItem("profileId")
  
    async function TransferToCat(e){
      e.preventDefault()
      // setShow(!show)
      // const PROFILE_ID = window.localStorage.getItem("profileId")
      if(activeIngresos){
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/transfer/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: JSON.stringify({category_a:Number(identificador), category_b:Number(prefCat), balance:Number(transferAmount.replace(/,/g,"")),  month_id:Number(window.localStorage.getItem("monthId"))})
          });
          if (response.status === 204 || response.status === 200) {
            // setLoader(false)
            console.log('Changed successfully');
            // console.log(response);
            // const data = await response.json();
            window.location.reload()
            // console.log(data);
          } else {
            window.location.reload()
              // console.log(response.status);
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
        } catch (error) {
          console.log(error);
        }
      } else if (activeEgresos) {
        try {
            const formData = {category_a:Number(identificador), category_b:Number(prefCat), balance:(Number(transferAmount.replace(/,/g,""))*(-1)), month_id:Number(window.localStorage.getItem("monthId"))}
            // alert(JSON.stringify(formData));
            
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/transfer/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`,
              "Accept": "application/json"
            },
            withCredentials:true,
            body: JSON.stringify(formData)
          });
          if (response.status === 204 || response.status === 200) {
            // setLoader(false)
            console.log('Changed successfully');
            // alert(Number(transferAmount.replace(/,/g,"")));
            
            // console.log(response);
            window.location.reload()
            // const data = await response.json();
            // console.log(data);
          } else {
            console.log(response.status);
            window.location.reload()
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
        } catch (error) {
          console.log(error);
        }
      }
    }
    
    function HandleTransactionClick1() {
      setActiveIngresos(true);
      setActiveEgresos(false);
    }
    function HandleTransactionClick2() {
      setActiveIngresos(false);
      setActiveEgresos(true);
    }
  
    return (
      <div className="confirmacion_date_container">
          <div className="conf_subcontainer">
              <img onClick={() => setShow(false)} src={cross_exit} alt="cerrar" />
              <form onSubmit={TransferToCat}>
                <label onClick={() => {  console.log({category_a:Number(identificador), category_b:Number(prefCat), balance:Number(transferAmount.replace(/,/g,"")), profile_id:Number(PROFILE_ID)});}}>Transferir dinero a:</label>
                <select required onChange={handleCatChange}>
                <option value={1} disabled selected>Seleccionar fondo</option>
                  {categories.filter(cat => cat.id !== identificador).map((cat) => (
                    <option key={cat.id} value={cat.id}>{cat.name}</option>
                  ))}
                  {sharedCategories.filter(cat => cat.id !== identificador).map((cat) => (
                    <option key={cat.id} value={cat.id}>{cat.name}</option>
                  ))}
                </select>
                <div className="ing_egr">
                  <p
                    onClick={HandleTransactionClick1}
                    className={activeIngresos ? "cat_egresos" : "cat_ingresos"}
                  >
                  Ingreso
                  </p>
                  <p
                    onClick={HandleTransactionClick2}
                    className={activeEgresos ? "cat_egresos" : "cat_ingresos"}
                  >
                  Egreso
                  </p>
                </div>
                {/* <span>Recuerde de usar "-" entre los números.</span> */}
                <label>Monto:</label>
                <input type="text"
                  required
                  placeholder='xxxxxx'
                  value={transferAmount}
                  onChange={HandleMontoChange}
                  // max={new Date().toISOString().split('T')[0]}
                />
                <button>Transferir</button>
              </form>
          </div>
      </div>
    )
  }
  
  function IngresarContraseña({show, setShow, setAllowAction, passwordAction}) {
  
  
    const [password, setPassword] = useState("")
    const [showContraError, setShowContraError] = useState(false)
  
    const navigate = useNavigate()
  
    async function handleContraSubmit(e){
      e.preventDefault();
      const TOKEN = window.localStorage.getItem("loggedToken")
      const formCategoryData = {password:password}
      
      // console.log(formCategoryData);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pass/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data.detail);
          window.localStorage.setItem("aproved", true)
          window.location.reload()
          setAllowAction(true)
          setShow(false)
          if(passwordAction === "categorias"){
            navigate("/categorias")
          } else if (passwordAction === "automatizaciones") {          
            navigate("/automatizaciones")
          }
          } else {
            // setLoader(false)
            setShowContraError(true)
            console.log(response.status);
            setPassword("")
            
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }
  
  return (
    <div className='add_category_container'>
        <form onSubmit={handleContraSubmit}>
            <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
            <label htmlFor="contraseña">Ingrese contraseña</label>
            {showContraError
            ? <span style={{color: "#e74504", fontSize:"14px"}}>Contraseña Incorrecta</span>
            : ""
            }
            <input 
                type="password"
                id='contraseña'
                placeholder='Contraseña...'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
  }
  
import "../styles/Header.scss"
// import arrows from "../assets/arrows_date.svg"
import {  useState, useEffect } from "react";

function Header() {

  const [profile, setProfile] = useState("")
  const [perfil, setPerfil] = useState("")

  useEffect(() => {
    if (!window.localStorage.getItem("walletId")) {
      const PROFILE_ID = window.localStorage.getItem("userName")
      setProfile(JSON.parse(PROFILE_ID))
      
    } else {
      // const PROFILE_ID = window.localStorage.getItem("userName")
      if(window.localStorage.getItem("compartida")){
        const WALLET_ID = window.localStorage.getItem("walletId")
        const TOKEN = window.localStorage.getItem("loggedToken")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.name))

      } else {
        const TOKEN = window.localStorage.getItem("loggedToken")
        const WALLET_ID = window.localStorage.getItem("walletId")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        // .then(data => console.log(data))
        .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.name))
      }
      // setProfile(JSON.parse(PROFILE_ID))

    }



  },[])

      useEffect(() => {
      const PROFILE_ID = window.localStorage.getItem("userName")
      setPerfil(JSON.parse(PROFILE_ID))
    },[])


    // useEffect(() => {
    //     const TOKEN = window.localStorage.getItem("loggedToken")
    //     const PROFILE_ID = window.localStorage.getItem("profileId")
    
    //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/`;
    //     const headers = {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Token ${JSON.parse(TOKEN)}`
    //     };
    
    //     fetch(url,{ headers, withCredentials:true })
    //     .then(response => response.json())
    //     .then(data => setMonths(data.months[data.months.length-1]))
    // },[])

    
    // useEffect(() => {
    //   const PROFILE_ID = window.localStorage.getItem("profileId")
    //   const TOKEN = window.localStorage.getItem("loggedToken")
  
    //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/profiles/`;
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Token ${JSON.parse(TOKEN)}`
    //   };
  
    //   fetch(url,{ headers, withCredentials:true })
    //   .then(response => response.json())
    //   .then(data => BringDatos(data.filter(user => user.id === Number(PROFILE_ID))[0].image))
    // },[])

    // async function BringDatos(name){
    //   setProfileImg(`${process.env.REACT_APP_BACKEND_URL}${name}`)
    //   }

    // const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    // const fecha = new Date()
    // const dia = fecha.getDate();
    // const mes = fecha.getMonth() ;
    // const año = fecha.getFullYear();


  return (
    <header>
        <h1>{window.localStorage.getItem("walletId")? "Visualizando":"Bienvenido"} "<span>{profile}</span>"</h1>
            {/* <img src={arrows} alt="flechas" />
            <span>{meses[mes]} {dia}, {año}</span> */}
        {/* <div className="date_container">
        </div> */}
        <select>
          <option>{perfil}</option>
        </select>
    </header>
  )
}

export default Header
import edit_row from "../assets/edit_row.svg"
import delete_icon from "../assets/delete_icon.svg"
import more_less from "../assets/more_less.svg"
import "../styles/Categorias.scss"
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function RowProducto({editable, icon, setIconProd, price, amount, setCantProd, setNameProd, setPriceProd, walletId,setWalletForTransac, dueñoId, setShowTransfer, setIfShared, compartida, setApiUrl, setModifyId, showModify, setShowModify, nombreCat, identificador,showAddTransaction,setShowAddTransaction, setCategoryId,showConfirmation,setShowConfirmation}) {

    // const navigate = useNavigate();

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); 
    const yyyy = today.getFullYear();
  
    const formattedDate = `${dd}/${mm}/${yyyy}`;
  
    // function HandleCheck(){
    //   const storageId = window.localStorage.getItem("categoryId")
    //   // setCategoryId(identificador)
    //   console.log(Number(storageId));
    //   if (!storageId) {
    //     window.localStorage.setItem("categoryId", identificador)
    //     navigate("/home")
    //   } else if(Number(storageId) !== identificador) {
    //     window.localStorage.removeItem("categoryId")
    //     window.localStorage.setItem("categoryId",identificador)
    //     navigate("/home")
    //   } else {
    //     window.localStorage.removeItem("categoryId")
    //     window.location.reload()
    //   }
    // }

    const [compartido, setCompartido] = useState(false)
    const [gastos, setGastos] = useState([])
    const [walletName, setWalletName] = useState([{name:""}])
  
    useEffect(() => {
        // setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        // .then(data => setPrefCurrency(data.pref_currency))
        // .then(data => console.log(data))
        .then(data => {
            if(data.months.length === 0){
                BringDatos(0)
            } else {
                BringDatos(Number(window.localStorage.getItem("monthId")))
            }
        })
        // .finally(        BringDatos())
        // eslint-disable-next-line
      },[])
  
      useEffect(() => {
  
        const TOKEN = window.localStorage.getItem("loggedToken")
        // const PROFILE_ID = window.localStorage.getItem("profileId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => CombineCategories(data.filter(wall => wall.id === walletId)))
  // eslint-disable-next-line
      },[])
  
    async function BringDatos(id){
  
        const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        const formData = {month_id:id , id:Number(PROFILE_ID)}
        // console.log(formData);
      
        if(id === 0){
            setGastos([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/byprod/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // data.pop()
                  console.log(data);
                  setGastos(data.filter(prod => prod.product === nombreCat));
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
  
      async function CombineCategories(local){
  
          if(local.length === 0){
            setCompartido(true)
          }
  
          const TOKEN = window.localStorage.getItem("loggedToken");
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${JSON.parse(TOKEN)}`,
          };
      
          fetch(url, { headers, withCredentials: true })
            .then((response) => response.json())
            .then((data) => setWalletName(data.concat(local).filter(wall => wall.id === walletId)));
  
      }
  
    return (
      <>
        <div>
            <div className='table_row1'>
              <p className='row_date'>{formattedDate}</p>
              <p className='row_category'>{nombreCat} ({walletName[0]?.name}) {compartido ?"(compartida)":""}</p>
              {window.localStorage.getItem("compartida")
                ? <p className='row_category2'>{gastos.length === 0 ? "" : `${gastos[0].total_amount.toLocaleString('es-MX')}`}</p>
                : <p className='row_category2'>{gastos.length === 0 ? "" : `${gastos[0].total_amount.toLocaleString('es-MX')}`}</p>
              }
              <span></span>
              {window.localStorage.getItem("editable")
                ?
                <>
                  <div className='row_btns'>
                    <button className='transac_btn2' onClick={() => {setWalletForTransac(walletId);setShowAddTransaction(!showAddTransaction); setCategoryId(identificador); setIfShared(compartida)}}>
                      Realizar Registro
                    </button>
                    <button onClick={() => {setShowTransfer(true); setCategoryId(identificador)}} className='row_btn'>
                        <img style={{width:"11px"}} src={more_less} alt="delete transac" />
                    </button>
                    <button onClick={() => {setShowModify(!showModify); setModifyId(identificador); setCantProd(amount); setNameProd(nombreCat); setPriceProd(price);setIconProd(icon)}} className='row_btn'>
                      <img src={edit_row} alt="" />
                    </button>
                    <button onClick={() => {setShowConfirmation(!showConfirmation); setCategoryId(identificador); setApiUrl("")}} className='row_btn'>
                      <img src={delete_icon} alt="" />
                    </button>
                    {/* <input  style={{marginLeft:"10px"}} type="checkbox" /> */}
                    {/* <button style={Number(window.localStorage.getItem("categoryId")) === identificador ? {backgroundColor:"#E74504", color:"white", border:"none"}:{border:"1px solid #9F9F9F"} } className='row_btn' onClick={HandleCheck}>
                      Ver
                    </button> */}
                  </div>
                </>
                : <div className="row_btns"></div>
              }
            </div>
        </div>
      </>
    )
}

export default RowProducto
import React, { useState} from 'react'
import "../styles/Sidebar.scss"
// import principal_icon from "../assets/pricipal-link-icon.svg"
// import automat_icon from "../assets/automat_icon.svg"
// import cuentas_icon from "../assets/cuentas_icon.svg"
// import ajustes_icon from "../assets/ajustes_icon.svg"
// import categ_icon from "../assets/categ_icon.svg"
// import logout_icon from "../assets/logout_icon.svg"
// import home_icono from "../assets/home_icono.svg"
// import ajustes_icono from "../assets/ajustes_icono.svg"
// import cuentas_icono from "../assets/cuentas_icono.svg"
// import automatizacion_icono from "../assets/automatizacion_icono.svg"
import new_fondos from "../assets/new_fondos.svg"
import new_ajustes from "../assets/new_ajustes.svg"
import new_home from "../assets/new_home.svg"
import new_automat from "../assets/new_automat.svg"
import new_logout from "../assets/new_logout.svg"
import new_cuentas from "../assets/new_cuentas.svg"
import new_fondos_dark from "../assets/new_fondos_dark.svg"
import new_ajustes_dark from "../assets/new_ajustes_dark.svg"
import new_home_dark from "../assets/new_home_dark.svg"
import new_automat_dark from "../assets/new_automat_dark.svg"
import new_cuentas_dark from "../assets/new_cuentas_dark.svg"

// import change_acount from "../assets/change_acount_icon.svg"
// import perfiles_icon from "../assets/perfiles_icon.svg"
import { Link, useNavigate } from 'react-router-dom'
import SeleccionarPerfil from './SeleccionarPerfil'
// import noImage from "../assets/no-image.webp"

function Sidebar({setShow, setPasswordAction}) {

    // const [profile, setProfile] = useState("")
    const [hover, setHover] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [hover3, setHover3] = useState(false)
    const [hover4, setHover4] = useState(false)
    const [hover5, setHover5] = useState(false)
    // const [months, setMonths] = useState("")
    // const [profileImg, setProfileImg] = useState("")

    const navigate = useNavigate()

    // useEffect(() => {
    //   const PROFILE_ID = window.localStorage.getItem("userName")
    //   setProfile(JSON.parse(PROFILE_ID))
    //   // const PROFILE_ID = window.localStorage.getItem("profileId")
    //   // const TOKEN = window.localStorage.getItem("loggedToken")
  
    //   // const url = `${process.env.REACT_APP_BACKEND_URL}/api/profiles/`;
    //   // const headers = {
    //   //   'Content-Type': 'application/json',
    //   //   'Authorization': `Token ${JSON.parse(TOKEN)}`
    //   // };
  
    //   // fetch(url,{ headers, withCredentials:true })
    //   // .then(response => response.json())
    //   // .then(data => setProfile(data.filter(user => user.id === Number(PROFILE_ID))))
    // },[])


    // useEffect(() => {
    //     const TOKEN = window.localStorage.getItem("loggedToken")
    //     const PROFILE_ID = window.localStorage.getItem("profileId")
    
    //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/`;
    //     const headers = {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Token ${JSON.parse(TOKEN)}`
    //     };
    
    //     fetch(url,{ headers, withCredentials:true })
    //     .then(response => response.json())
    //     .then(data => setMonths(data.months[data.months.length-1]))
    // },[])

    
    // useEffect(() => {
    //   const PROFILE_ID = window.localStorage.getItem("profileId")
    //   const TOKEN = window.localStorage.getItem("loggedToken")
  
    //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/profiles/`;
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Token ${JSON.parse(TOKEN)}`
    //   };
  
    //   fetch(url,{ headers, withCredentials:true })
    //   .then(response => response.json())
    //   .then(data => BringDatos(data.filter(user => user.id === Number(PROFILE_ID))[0].image))
    // },[])

    // async function BringDatos(name){
    //   setProfileImg(`${process.env.REACT_APP_BACKEND_URL}${name}`)
    //   }

    const [showProfiles, setShowProfiles] = useState(false)



    const linkStyle = {
        textDecoration: "none",
        color: '#A9A9A9'
      };

    function handleLogout(){
        window.localStorage.clear()
        navigate("/login")
    }  



  return (
    <aside>
        <h1>Maslana</h1>
        <nav>
            <ul>
                {window.localStorage.getItem("walletId")
                  ? <>
                  <Link className='custom_link' to="/home" style={linkStyle}><li onMouseLeave={() => {setHover(false)}} onMouseEnter={() => {setHover(true)}}><img src={hover?new_home_dark:new_home} alt="Icono principal" /><span>Principal</span></li></Link>
                  <Link className='custom_link' to="/cuentas" style={linkStyle}><li onMouseLeave={() => {setHover2(false)}} onMouseEnter={() => {setHover2(true)}}><img className='cuentas_img' src={hover2?new_cuentas_dark:new_cuentas} alt="Icono cuentas" /><span>Cuentas</span></li></Link>
                  {!window.localStorage.getItem("prevMonth") || window.localStorage.getItem("aproved")
                    ?                   
                  <>
                    <Link className='custom_link' to={window.localStorage.getItem("products")?"/productos":"/categorias"} style={linkStyle}><li onMouseLeave={() => {setHover3(false)}} onMouseEnter={() => {setHover3(true)}}><img src={hover3?new_fondos_dark:new_fondos} alt="Icono categoria" /><span>Fondos y transacciones</span></li></Link>
                    {window.localStorage.getItem("products")
                      ? ""
                      : <Link className='custom_link' to="/automatizaciones" style={linkStyle}><li onMouseLeave={() => {setHover4(false)}} onMouseEnter={() => {setHover4(true)}}><img src={hover4?new_automat_dark:new_automat} alt="Icono automatizacion" /><span>Automatización</span></li></Link>
                    }
                  </>
                    : 
                    <>
                      <span className='custom_link' onClick={() => {setShow(true); setPasswordAction("categorias")}} style={linkStyle}><li onMouseLeave={() => {setHover3(false)}} onMouseEnter={() => {setHover3(true)}}><img src={hover3?new_fondos_dark:new_fondos} alt="Icono categoria" /><span>Fondos y transacciones</span></li></span>
                      <span className='custom_link' onClick={() => {setShow(true); setPasswordAction("automatizaciones")}} style={linkStyle}><li onMouseLeave={() => {setHover4(false)}} onMouseEnter={() => {setHover4(true)}}><img src={hover4?new_automat_dark:new_automat} alt="Icono automatizacion" /><span>Automatización</span></li></span>
                    </>
                  }
                    </>
                :""
              }
                {/* <Link className='custom_link' to="/perfiles" style={linkStyle}><li><img src={perfiles_icon} alt="Icono perfiles" /><span>Perfiles</span></li></Link> */}
                <Link className='custom_link' to="/ajustes" style={linkStyle}><li onMouseLeave={() => {setHover5(false)}} onMouseEnter={() => {setHover5(true)}}><img src={hover5?new_ajustes_dark:new_ajustes} alt="Icono ajustes" /><span>Ajustes</span></li></Link>
                {/* <Link className='custom_link' to="/layout" style={linkStyle}><li onMouseLeave={() => {setHover5(false)}} onMouseEnter={() => {setHover5(true)}}><img src={hover5?new_ajustes_dark:new_ajustes} alt="Icono ajustes" /><span>Layout</span></li></Link> */}
                {/* <Link className='custom_link' to="/seleccion-cuentas" style={linkStyle}><li><img src={ajustes_icon} alt="Icono ajustes" /><span>Seleccion cuentas</span></li></Link> */}
            </ul>
        </nav>
        <div /*onClick={() => {setShowProfiles(!showProfiles)}}*/ className='bottom_aside'>
            {/* <Link style={{textDecoration:""}}  to="/login"> */}
              <button onClick={handleLogout} className='logout_btn'>
                <img src={new_logout} alt="logout icon" />
                <span>Cerrar sesión</span>
              </button>
            {/* </Link>       */}
            {/* <div className='line-break'></div>
            <div className='perfil'>
                <span></span>
                <div className='datos_perfil'>
                    <p>{profile}</p>
                </div>
                <span></span>
            </div> */}
                {/* <img className='foto_perfil' src={profileImg} alt="user" /> */}
                {/* <img className='cambiar_cuenta' src={change_acount} alt="change acount" /> */}
        </div>
        {showProfiles 
            ? <SeleccionarPerfil showProfiles={showProfiles} setShowProfiles={setShowProfiles} />
            : ""
        }
       
    </aside>
  )
}

export default Sidebar

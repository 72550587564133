import React, { useEffect, useState } from "react";
import "../styles/Login.scss";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
// import google_icon from "../assets/google_icon.svg"
import cross_exit from "../assets/cross_exit.svg";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./Loading";

function Login() {
	const [remember, setRemember] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showLoginError, setShowLoginError] = useState(false);
	const [loader, setLoader] = useState(false);
	// const [auth, setAuth] = useState(null);
	const navigate = useNavigate();
	const [viewPassword, setViewPassword] = useState(false);

	useEffect(() => {
		const getEmail = window.sessionStorage.getItem("username");
		const getPassword = window.sessionStorage.getItem("password");

		if (getEmail !== null) {
			setEmail(getEmail);
			setPassword(getPassword);
			setRemember(true);
		}
	}, []);

	function HandleViewPassword() {
		setViewPassword(!viewPassword);
	}
	function HandleRemember() {
		setRemember(!remember);
	}

    async function handleSubmit(e){
      e.preventDefault();
      setLoader(true)
      if(remember){
        window.sessionStorage.setItem("username", email)
        window.sessionStorage.setItem("password", password)

        const formData = {username: email, password:password}
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials:true,
            body: JSON.stringify(formData),
          });
          
          if (response.status === 200) {
            setLoader(false)
            const data = await response.json();
            console.log(data);
            
            const accessToken = data.token;
            const userName = data.username;
            const profileId = data.id;
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Token ${accessToken}`
            };
        
            await fetch(url,{ headers, withCredentials:true })
            .then(response => response.json())
            .then(data =>  {
              if(data.length === 0){
                window.localStorage.setItem(
                  "loggedToken", JSON.stringify(accessToken)
                )
                window.localStorage.setItem(
                  "userName", JSON.stringify(userName)
                )
                window.localStorage.setItem(
                  "profileId", JSON.stringify(profileId)
                )
                navigate("/cuentas")
              } else {
                
                window.localStorage.setItem(
                  "loggedToken", JSON.stringify(accessToken)
                )
            window.localStorage.setItem(
              "userName", JSON.stringify(userName)
            )
            window.localStorage.setItem(
              "profileId", JSON.stringify(profileId)
            )

              navigate("/seleccion-cuentas")
              }

            })
            } else {
              setLoader(false)
              setShowLoginError(true)
              console.error('Error submitting form');
            }
          } catch (error) {
            console.error('Error:', error);
          }
          setEmail("");
          setPassword("");
      } else {
        window.sessionStorage.clear()
        const formData = {username: email, password:password}
      
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials:true,
            body: JSON.stringify(formData),
          });
          
          if (response.status === 200) {
            setLoader(false)
            const data = await response.json();
            console.log(data);
            
            const accessToken = data.token;
            const userName = data.username;
            const profileId = data.id;
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Token ${accessToken}`
            };
        
            await fetch(url,{ headers, withCredentials:true })
            .then(response => response.json())
            .then(data =>  {
              if(data.length === 0){
                window.localStorage.setItem(
                  "loggedToken", JSON.stringify(accessToken)
                )
                window.localStorage.setItem(
                  "userName", JSON.stringify(userName)
                )
                window.localStorage.setItem(
                  "profileId", JSON.stringify(profileId)
                )
                navigate("/cuentas")
              } else {
                
                
                window.localStorage.setItem(
                  "loggedToken", JSON.stringify(accessToken)
                )
            window.localStorage.setItem(
              "userName", JSON.stringify(userName)
            )
            window.localStorage.setItem(
              "profileId", JSON.stringify(profileId)
            )

              navigate("/seleccion-cuentas")
              }
            })


            } else {
              setLoader(false)
              setShowLoginError(true)              
              console.error('Error submitting form');
            }
          } catch (error) {
            console.error('Error:', error);
          }
          setEmail("");
          setPassword("");
      }
      }
      
      return (
    <>
      <div className='login'>
            <h1 className='login_title'>Bienvenido a <span>Maslana</span></h1>
          {/* <div className="left_bg">
          </div> */}
          <div className='login_container'>
              <form onSubmit={handleSubmit}>
                  <h1>Iniciar Sesión</h1>
                  <div className='input_email'>
                      <label htmlFor="email">Ingresa tu correo electrónico</label>
                      <input 
                          id='email'
                          type="email" 
                          placeholder='Correo electrónico...' 
                          value={email}
                          onChange={(e)=>{setEmail(e.target.value)}} 
                          required 
                      />
                  </div>
                  <div className='input_contraseña'>
                      <div className='flex_label'>
                          <label htmlFor="password">Ingresa tu contraseña</label>
                          <Link to="/cambiar-contra"><span>Olvidé mi Contraseña</span></Link>
                      </div>
                      <div className='flex_input_eye'>
                          <input 
                              id='password'
                              type={viewPassword ? "text" : "password"} 
                              placeholder='Contraseña...' 
                              value={password}
                              onChange={(e)=>{setPassword(e.target.value)}} 
                              required 
                          />
                          {viewPassword 
                          ? <IoEyeOutline onClick={HandleViewPassword} className='icon' /> 
                          : <IoEyeOffOutline onClick={HandleViewPassword} className='icon' />}
                      </div>
                  </div>
                  <div className='input_recuerdame'>
                      <label>
                        <input 
                          type="checkbox" 
                          checked={remember} 
                          onChange={HandleRemember} 
                        />
                        Recuerdame
                      </label>
                  </div>
                  <button className='login_btn' type="submit">Iniciar sesión</button>
                  {/* <div className='iniciar_con'>
                      <div className='iniciar_linea'></div>
                      <p>iniciar con</p>
                  </div>
                  <button className='login_google'><img className='google_icon' src={google_icon} alt="google icon" />Continue with Google</button> */}
                  <div className='crear_cuenta'>
                      <span>¿No tienes cuenta?</span>
                      <Link to="/signup"><p>Registrate</p></Link>
                  </div>
              </form>
          </div>
          <span style={{position:"absolute",bottom:"5px",right:"5px", color:"#fff"}}>Versión 1.25</span>
      </div>
      {showLoginError 
        ? <LoginFallido show={showLoginError} setShow={setShowLoginError} />
        : ""
      }
      {loader 
        ? <Loading/>
        : ""
      }                      
    </>
  )
}

export default Login;

function LoginFallido({show, setShow}) {

  return (
    <div className="registro_exitoso_container">
        <div className="reg_subcontainer">
            <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
            <h2>Error al iniciar sesion</h2>
            <p style={{textAlign:"center"}}>El correo electrónico o contraseña ingresados son incorrectos.</p>
        </div>
    </div>
  )
}

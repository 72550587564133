import React, { useState } from 'react'
import "../styles/AgregarCategoria.scss"
import cross_exit from "../assets/cross_exit.svg"


function AgregarComentario({show, setShow, identificador, comments}) {

    const [newComment, setNewComment] = useState("")
    const [showComment, setShowComment] = useState(false)
    const [comment, setComment] = useState("")

    async function handleCommentSubmit(e){
        e.preventDefault()
        setShow(!show)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const formCategoryData = { comment: newComment }
        
        console.log(formCategoryData);
  
        try {
          if(window.localStorage.getItem("products")){
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/comment/${identificador}/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${JSON.parse(TOKEN)}`
              },
              withCredentials:true,
              body: JSON.stringify(formCategoryData)
            });
            
            if (response.status === 200) {
              // setLoader(false)
              console.log('Form submitted successfully');
              const data = await response.json();
              console.log(data);
              window.location.reload()
              } else {
                // setLoader(false)
                // setShowLoginError(true)
                console.log(response.status);
                console.error('Error submitting form');
              } 
          } else {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/comment/${identificador}/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${JSON.parse(TOKEN)}`
              },
              withCredentials:true,
              body: JSON.stringify(formCategoryData)
            });
            
            if (response.status === 200) {
              // setLoader(false)
              console.log('Form submitted successfully');
              const data = await response.json();
              console.log(data);
              window.location.reload()
              } else {
                // setLoader(false)
                // setShowLoginError(true)
                console.log(response.status);
                console.error('Error submitting form');
              }
          }
          } catch (error) {
            console.error('Error:', error);
          }
    }

    async function deleteComment(comentario){
      setShowComment(!showComment)
      setComment(comentario)
    }

  return (
    <div className='add_comments_container'>
        <form onSubmit={handleCommentSubmit}>
            <img className='close_cont' onClick={() => {setShow(!show)}} src={cross_exit} alt="close" />
            <label onClick={() => {console.log(comments)}}>Tus comentarios</label>
            {comments[0].comments[0] === ""
              ? ""
              : <ul>
              {comments[0].comments?.map((e, index) => (
                  <li key={index}>
                    <span>{e}</span>
                    <i onClick={() => {deleteComment(e)}} className="fa-solid fa-trash" style={{color: "#e32626"}}></i>
                  </li>
              ))
              }
          </ul>
            }
            
            <hr />
            <label htmlFor="nuevoComentario">+ Detalle de ingreso</label>
            <input 
                type="text"
                id='nuevoComentario'
                placeholder='Dinero por proyecto de...'
                required
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
            />
            <button type='submit' >Agregar</button>
        {showComment
          ? <ConfirmDeleteUser setConfirmDelete={setShowComment} comentario={comment} identificador={identificador} />
          : ""
        }
        </form>
    </div>
  )
}

export default AgregarComentario

function ConfirmDeleteUser({identificador, setConfirmDelete, comentario}){

  async function DeleteComment(){
    const TOKEN = window.localStorage.getItem("loggedToken")
    const formCategoryData = { comment: comentario }
    try {
      if(window.localStorage.getItem("products")){
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/rcomment/${identificador}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        },
        withCredentials:true,
        body: JSON.stringify(formCategoryData)
      });
      window.location.reload()
      } else {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/rcomment/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        window.location.reload()
      }
    } catch (error) {
      console.error(error);
    }
  }

  return(
    <div className='delete_container'>
      <p>¿Desea eliminar este comentario?</p>
      <div className='flex_btns'>
        <span className='confirm_btn' onClick={DeleteComment}>Si</span>
        <span className='decline_btn' onClick={() => {setConfirmDelete(false)}}>No</span>
      </div>
    </div>
  )
}
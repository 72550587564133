import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom'
import "../styles/Categorias.scss"
import fondos_svgg from "../assets/fondos_svgg.svg"
import cross_exit from "../assets/cross_exit.svg";
import Loading from './Loading'
// import TablaTransacciones from './TablaTransacciones'
import AgregarComentario from './AgregarComentario'
import AgregarProducto from './AgregarProducto'
import AgregarProdTransac from './AgregarProdTransac'
import TablaTransProd from './TablaTransProd'
import RowProductos from './RowProductos'
import ModificarProducto from './ModificarProducto'
import TablaDisponibles from './TablaDisponibles'

function CategoriasProductos() {

    const [showAddCategory, setShowAddCategory] = useState(false)
    const [apiUrl, setApiUrl] = useState("")
  
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showTransfer, setShowTransfer] = useState(false)
    const [showModifyCategory, setShowModifyCategory] = useState(false)
    const [showAddProdTransac, setShowAddProdTransac] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [modifyId, setModifyId] = useState(null)
    const [comments, setComments] = useState([])
    const [showDate, setShowDate] = useState(false)
    const [ifShared, setIfShared] = useState(false)
    const [walletForTransac, setWalletForTransac] = useState(0)
    const [allMonths, setAllMonths] = useState([])
    const [cantProd, setCantProd] = useState("")
    const [nameProd, setNameProd] = useState("")
    const [priceProd, setPriceProd] = useState("")
    const [iconProd, setIconProd] = useState("")
  
  
  
    const [categoryId, setCategoryId] = useState(0);
    const [productos, setProductos] = useState([]);
    const [sharedCategorias,setSharedCategorias] = useState([]);
    const [sharedCategoriasVisual,setSharedCategoriasVisual] = useState([]);
    const [transacciones, setTransacciones] = useState([]);
    const [transaccionesPendientes, setTransaccionesPendientes] = useState([]);
    const [transaccionesPendientesVisibles, setTransaccionesPendientesVisibles] = useState([]);
    const [sharedTransacciones, setSharedTransacciones] = useState([]);
    const [sharedTransaccionesVisibles, setSharedTransaccionesVisibles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activeIngresos, setActiveIngresos] = useState(true);
    const [activeEgresos, setActiveEgresos] = useState(false);
    // const [pendientes, setPendientes] = useState(false);
    const [showContra, setShowContra] = useState(false)
    const [passwordAction, setPasswordAction] = useState("")
    const [months, setMonths] = useState("")

    

    // TRAE TODOS LOS PRODUCTOS
    useEffect(() => {
        if(window.localStorage.getItem("walletId")){
          setLoader(true)
          const TOKEN = window.localStorage.getItem("loggedToken")
          const WALLET_ID = window.localStorage.getItem("walletId")
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${WALLET_ID}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          .then(data => setProductos(data))
          .finally(setLoader(false))
        } else {
    
            setLoader(true)
            const TOKEN = window.localStorage.getItem("loggedToken")
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/categories/`;
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            };
        
            fetch(url,{ headers, withCredentials:true })
            .then(response => response.json())
            .then(data => setProductos(data))
            .finally(setLoader(false))
        
      }
    
      },[]);

    // TRAE COMPARTIDOS
      useEffect(() => {
        setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => {divideEditionCat(data.filter(prods => prods.isproduct === true));divideEditionTransac(data.filter(prods => prods.isproduct === true));divideEditionDisponibles(data.filter(prods => prods.isproduct === true))})
        .finally(setLoader(false))
        // eslint-disable-next-line
      },[]);
    
    // TRAE MESES
      useEffect(() => {
        // setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        // .then(data => setPrefCurrency(data.pref_currency))
        // .then(data => console.log(data))
        .then(data => {
          setAllMonths(data.months);
      })
        // .finally(        BringDatos())
      },[])
      // TRANSAC COMPARTIDAS
      // useEffect(() => {
      //   setLoader(true)
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => sharedTransac(data))
      //   .finally(setLoader(false))
      //   // eslint-disable-next-line
      // },[]); 
      async function divideEditionCat(compartidas){
        const PROFILE_ID = window.localStorage.getItem("profileId") 
        // console.log(compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0]) 
        // console.log(compartidas) 
        if (compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0] === Number(PROFILE_ID)) {
          sharedDatos(compartidas)
        } else {
          sharedDatos2(compartidas)
        }
      }

      async function sharedDatos(compartidos){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
        // console.log(compartidos[0].id);
      
        if(compartidos.length === 0){
          setSharedCategorias([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${compartidos[0].id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  // body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setSharedCategorias(data);
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
      async function sharedDatos2(compartidos){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
        // console.log(compartidos);
      
        if(compartidos.length === 0){
          setSharedCategoriasVisual([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${compartidos[0].id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  // body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setSharedCategoriasVisual(data);
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }

        async function divideEditionTransac(compartidas){
          const PROFILE_ID = window.localStorage.getItem("profileId") 
          // console.log(compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0]) 
          if (compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0] === Number(PROFILE_ID)) {
            billeteraCompartida(compartidas)
          } else {
            billeteraCompartida2(compartidas)
          }
        }
    
        async function billeteraCompartida(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:MONTH_ID}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/wallets/get/${cuentas[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    sharedTransac(data.Months);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
        async function billeteraCompartida2(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:MONTH_ID}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/wallets/get/${cuentas[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    sharedTransac2(data.Months);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
        async function sharedTransac(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const formData = {idMonth:cuentas[0].id, idWallet:cuentas[cuentas.length -1].idWallet}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransacciones([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    pruebaCuentas(data);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
        async function sharedTransac2(cuentas){
          // const MONTH_ID = window.localStorage.getItem("monthId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
          const formData = {idMonth:cuentas[0].id, idWallet:cuentas[cuentas.length -1].idWallet}
          // console.log(compartidos);
        
          if(cuentas.length === 0){
            setSharedTransaccionesVisibles([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                    const data = await response.json();
                    pruebaCuentas2(data);
                    } else {
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
        async function pruebaCuentas(filtrados){
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          // const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(compartidos);
        
          if(filtrados.length === 0){
            console.log([])
          } else {
            setSharedTransacciones(filtrados)
              // try {
              //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/`, {
              //       method: 'GET',
              //       headers: {
              //         'Content-Type': 'application/json',
              //         'Authorization': `Token ${JSON.parse(TOKEN)}`
              //       },
              //       withCredentials:true,
              //       // body: JSON.stringify(formData),
              //     });
                  
              //     if (response.status === 200) {
              //       const data = await response.json();
              //       const perfilPrincipal = data.filter(user => user.id === Number(PROFILE_ID))
              //       setSharedTransacciones(filtrados.filter(cuentas => cuentas.name === perfilPrincipal[0].name));
                    
              //       } else {
              //         console.error('Error submitting form');
              //       }
              //     } catch (error) {
              //       console.error('Error:', error);
              //     }
                }
          }
        async function pruebaCuentas2(filtrados){
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          // const TOKEN = window.localStorage.getItem("loggedToken")
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(compartidos);
        
          if(filtrados.length === 0){
            console.log([])
          } else {
            setSharedTransaccionesVisibles(filtrados)
              // try {
              //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/`, {
              //       method: 'GET',
              //       headers: {
              //         'Content-Type': 'application/json',
              //         'Authorization': `Token ${JSON.parse(TOKEN)}`
              //       },
              //       withCredentials:true,
              //       // body: JSON.stringify(formData),
              //     });
                  
              //     if (response.status === 200) {
              //       const data = await response.json();
              //       const perfilPrincipal = data.filter(user => user.id === Number(PROFILE_ID))
              //       setSharedTransacciones(filtrados.filter(cuentas => cuentas.name === perfilPrincipal[0].name));
                    
              //       } else {
              //         console.error('Error submitting form');
              //       }
              //     } catch (error) {
              //       console.error('Error:', error);
              //     }
                }
          }
    
      // useEffect(() => {
      //   setLoader(true)
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/cat/23/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => setSharedCategorias(data))
      //   .finally(setLoader(false))
    
      // },[]);
    
      // useEffect(() => {
    
      //   // setLoader(true)
      //   const PROFILE_ID = window.localStorage.getItem("profileId")
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/transactions/byprof/${PROFILE_ID}/`;
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Token ${JSON.parse(TOKEN)}`
      //   };
    
      //   fetch(url,{ headers, withCredentials:true })
      //   .then(response => response.json())
      //   .then(data => setTransacciones(data.transactions.sort((a, b) => new Date(a.date) - new Date(b.date))))
      //   // .finally(setLoader(false))
    
      // },[]);
    
      useEffect(() => {
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => setMonths(data.months[data.months.length-1]))
    },[])
    
      useEffect(() => {
        // setLoader(true)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
    
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        // .then(data => setPrefCurrency(data.pref_currency))
        // .then(data => console.log(data))
        .then(data => {
            if(data.months.length === 0){
                BringDatos(0)
            } else {
                BringDatos(Number(window.localStorage.getItem("monthId")))
                // BringDatosPendientes(Number(window.localStorage.getItem("monthId")))
            }
        })
      },[])
    
      async function HandleMonth(e){
        const MONTH_ID = e.target.value;
            
        if(Number(MONTH_ID) === months.id){
          window.localStorage.removeItem("prevMonth")
          window.localStorage.removeItem("aproved")
        } else {
          window.localStorage.setItem("prevMonth", true)
        }
        window.localStorage.setItem("monthId", MONTH_ID)
        window.location.reload()
      }
    
      async function BringDatos(id){
    
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
        const WALLET_ID = window.localStorage.getItem("walletId")
      
        const formData = {idMonth:id , idWallet:Number(WALLET_ID)}
        // console.log(formData);
      
        if(id === 0){
          setTransacciones([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/list/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                  // console.log(data)
                  setTransacciones(data.filter((tran) => tran.idWallet === Number(window.localStorage.getItem("walletId"))).sort((a, b) => new Date(a.date) - new Date(b.date)));
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
        }
    
        async function divideEditionDisponibles(compartidas){
          const PROFILE_ID = window.localStorage.getItem("profileId") 
          // console.log(compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0]) 
          if (compartidas[0]?.sharededit?.filter(e => e === Number(PROFILE_ID))[0] === Number(PROFILE_ID)) {
            BringDatosPendientes(compartidas)
          } else {
            BringDatosPendientes2(compartidas)
          }
        }

        async function BringDatosPendientes(product){
    
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
        
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(product);
        
          if(product.length === 0){
            setTransaccionesPendientes([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${product[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    setTransaccionesPendientes(data);
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
        async function BringDatosPendientes2(product){
    
          // const PROFILE_ID = window.localStorage.getItem("profileId")  
          const TOKEN = window.localStorage.getItem("loggedToken")
        
          // const formData = {month_id:id , profile_id:Number(PROFILE_ID)}
          // console.log(product);
        
          if(product.length === 0){
            setTransaccionesPendientesVisibles([])
          } else {
              try {
                  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${product[0].id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Token ${JSON.parse(TOKEN)}`
                    },
                    withCredentials:true,
                    // body: JSON.stringify(formData),
                  });
                  
                  if (response.status === 200) {
                  //   setLoader(false)
                    const data = await response.json();
                    // console.log(data)
                    setTransaccionesPendientesVisibles(data);
          
                      // navigate("/home")
                    } else {
                      // setLoader(false)
                      // setShowLoginError(true)
                      console.error('Error submitting form');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
          }
    
      function HandleTransactionClick1(){
        setActiveIngresos(true)
        setActiveEgresos(false)
      }
      function HandleTransactionClick2(){
        setActiveIngresos(false)
        setActiveEgresos(true)
      }



  return (
    <>
    <Header />
    <Sidebar
      setShow={setShowContra} 
      setPasswordAction={setPasswordAction}
    />
    <div className='cat_container'>
        {/* <button onClick={DescargaArchivo}>Descargar transacciones</button> */}
        <div className='add_cat_container'>
        {window.localStorage.getItem("editable")
                  ? <button className='add_category' onClick={() => setShowAddCategory(!showAddCategory)}><img src={fondos_svgg} alt="add category" />Agregar Producto</button>
                  : <button className='add_category'><img src={fondos_svgg} alt="add category" />Agregar Producto</button>
            } 
          
          {allMonths.length === 0 
              ? <p className='visual_month'>Mes visualizado <span>Ninguno</span></p>
              : <p className='visual_month'>Mes visualizado <select className="month_selector" onChange={HandleMonth}>{allMonths?.map((month) => (
                  month.id === Number(window.localStorage.getItem("monthId")) 
                    ? <option className="option_month" selected key={month.id} value={month.id}>{month.name}</option>
                    : <option className="option_month" key={month.id} value={month.id}>{month.name}</option>
                  
                  
              ))}</select></p>
          }
        </div>
        <div className="full_table">
          <div className='cat_title_container'>
            <p className='cat_title_1'>Productos</p>
          </div>
            <div className='table_header'>
              <p className='header_title1'>Fecha</p>
              <p className='header_title2'>Nombre Producto</p>
              <p className='header_title3'>Movimientos Totales</p>
              <span></span>
            </div>
          <div className='cat_table'>
            <ul>
              {window.localStorage.getItem("compartida") 
              ? 
                <>
              {sharedCategorias?.map((e) => (
                <li key={e.id}>
                  <RowProductos
                    walletId={e.idWallet}
                    dueñoId={e.user}
                    categorias={productos} 
                    cambioCategorias={setProductos} 
                    nombreCat={e.name} 
                    identificador={e.id} 
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    showAddTransaction={showAddProdTransac}
                    setShowAddTransaction={setShowAddProdTransac}
                    setCategoryId={setCategoryId}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setApiUrl={setApiUrl}
                    compartida={false}
                    setIfShared={setIfShared}
                    setShowTransfer={setShowTransfer}
                    setWalletForTransac={setWalletForTransac}
                    setCantProd={setCantProd}
                    setNameProd={setNameProd}
                    setPriceProd={setPriceProd}
                    setIconProd={setIconProd}
                    amount={e.amount}
                    price={e.price}
                    icon={e.icon}
                    editable={true}
                  />
                </li>
              ))}
              {sharedCategoriasVisual?.map((e) => (
                <li key={e.id}>
                  <RowProductos
                    walletId={e.idWallet}
                    dueñoId={e.user}
                    categorias={productos} 
                    cambioCategorias={setProductos} 
                    nombreCat={e.name} 
                    identificador={e.id} 
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    showAddTransaction={showAddProdTransac}
                    setShowAddTransaction={setShowAddProdTransac}
                    setCategoryId={setCategoryId}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setApiUrl={setApiUrl}
                    compartida={false}
                    setIfShared={setIfShared}
                    setShowTransfer={setShowTransfer}
                    setWalletForTransac={setWalletForTransac}
                    setCantProd={setCantProd}
                    setNameProd={setNameProd}
                    setPriceProd={setPriceProd}
                    setIconProd={setIconProd}
                    amount={e.amount}
                    price={e.price}
                    icon={e.icon}
                    editable={false}
                  />
                </li>
              ))}
                </>
              :
              productos?.map((e) => (
                <li key={e.id}>
                  <RowProductos
                    walletId={e.idWallet}
                    dueñoId={e.user}
                    categorias={productos} 
                    cambioCategorias={setProductos} 
                    nombreCat={e.name} 
                    identificador={e.id} 
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    showAddTransaction={showAddProdTransac}
                    setShowAddTransaction={setShowAddProdTransac}
                    setCategoryId={setCategoryId}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setApiUrl={setApiUrl}
                    compartida={false}
                    setIfShared={setIfShared}
                    setShowTransfer={setShowTransfer}
                    setWalletForTransac={setWalletForTransac}
                    setCantProd={setCantProd}
                    setNameProd={setNameProd}
                    setPriceProd={setPriceProd}
                    setIconProd={setIconProd}
                    total={e.total}
                    amount={e.amount}
                    price={e.price}
                    icon={e.icon}
                    editable={true}
                  />
                </li>
              ))          
              }


            </ul>
          </div>
        </div>
        <div className="full_table2">
          {/* <button className='add_category transac_btn' onClick={() => setShowAddTransac(!showAddTransac)}><img src={add_cat} alt="add category" />Transacciones</button> */}
          <div className='cat_title_container'>
            <p onClick={HandleTransactionClick1} className={activeIngresos ? 'cat_egresos' : 'cat_ingresos'}>Pagos</p>
            <p onClick={HandleTransactionClick2} className={activeEgresos ? 'cat_egresos' : 'cat_ingresos'}>Disponibles</p>
          </div>
          {/* SI ACTIVEINGRESOS ES TRUE FILTRAR LA TABLA POR UNOS U OTROS */}
            <div className='table_header2'>
                <p className='header_title1'>Fecha</p>
                {activeEgresos
                  ? <p className='header_title3'>Producto</p>
                  : <p className='header_title3'>Producto</p>
                }
                
                <p className='header_title_2'>Precio individual</p>
                <p className='header_title3'>Cantidad</p>
                <p className='header_title3'>TOTAL</p>
                <p className='dot_inv'>.</p>
            </div>
          <div className='cat_table'>
            {activeIngresos 
              ? <ul>
                {window.localStorage.getItem("compartidas")
                  ?
                  <>
                    {sharedTransacciones?.map((transac) => (
                      <TablaTransProd
                        key={transac.id}
                        date={transac.date} 
                        walletId={transac.idWallet}
                        clave={transac.name}
                        comments={transac.comments}
                        profile_name={transac.product_price}
                        currAmount={transac.amount}
                        idCategory={transac.idCategory}  
                        idProduct={transac.idProduct}  
                        categorias={productos}
                        identificador={transac.id}
                        showDate={showDate}
                        setShowDate={setShowDate}
                        showConfirmation={showConfirmation}
                        setShowConfirmation={setShowConfirmation}
                        showComments={showComments}
                        setShowComments={setShowComments}
                        setApiUrl={setApiUrl}
                        setCategoryId={setCategoryId}
                        setComments={setComments}
                        transactions={transacciones}
                        withCategory={false}
                        pendiente={false}
                        editable={true}
                      />
                    ))}
                    {sharedTransaccionesVisibles?.map((transac) => (
                      <TablaTransProd
                        key={transac.id}
                        date={transac.date} 
                        walletId={transac.idWallet}
                        clave={transac.name}
                        comments={transac.comments}
                        profile_name={transac.product_price}
                        currAmount={transac.amount}
                        idCategory={transac.idCategory}  
                        idProduct={transac.idProduct}  
                        categorias={productos}
                        identificador={transac.id}
                        showDate={showDate}
                        setShowDate={setShowDate}
                        showConfirmation={showConfirmation}
                        setShowConfirmation={setShowConfirmation}
                        showComments={showComments}
                        setShowComments={setShowComments}
                        setApiUrl={setApiUrl}
                        setCategoryId={setCategoryId}
                        setComments={setComments}
                        transactions={transacciones}
                        withCategory={false}
                        pendiente={false}
                        editable={false}
                      />
                    ))}
                  </>
                  :
                  transacciones?.map((transac) => (
                    <TablaTransProd
                      key={transac.id}
                      date={transac.date} 
                      walletId={transac.idWallet}
                      clave={transac.name}
                      comments={transac.comments}
                      profile_name={transac.product_price}
                      currAmount={transac.amount}
                      idCategory={transac.idCategory}  
                      idProduct={transac.idProduct}  
                      categorias={productos}
                      identificador={transac.id}
                      showDate={showDate}
                      setShowDate={setShowDate}
                      showConfirmation={showConfirmation}
                      setShowConfirmation={setShowConfirmation}
                      showComments={showComments}
                      setShowComments={setShowComments}
                      setApiUrl={setApiUrl}
                      setCategoryId={setCategoryId}
                      setComments={setComments}
                      transactions={transacciones}
                      withCategory={true}
                      pendiente={false}
                      editable={true}
                    />
                  ))
                }

                </ul>
            : 
             <ul>
              {window.localStorage.getItem("compartidas")
                ?
                <>
                  {transaccionesPendientes?.map((prod) => (
                  <TablaDisponibles 
                    key={prod.id}
                    // date={prod.date} 
                    walletId={prod.idWallet}
                    clave={prod.name}
                    comments={prod.comments}
                    profile_name={prod.price}
                    currAmount={prod.amount}
                    idCategory={prod.idCategory} 
                    categorias={productos}
                    identificador={prod.id} 
                    date={prod.date}
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    // showDate={showDate}
                    setShowTransfer={setShowTransfer}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    // showComments={showComments}
                    // setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    // setComments={setComments}
                    setCategoryId={setCategoryId}
                    // transactions={transacciones}
                    withCategory={true}
                    editable={true}
                    // pendiente={false}
                    />
                  ))}
                  {transaccionesPendientesVisibles?.map((prod) => (
                  <TablaDisponibles 
                    key={prod.id}
                    // date={prod.date} 
                    walletId={prod.idWallet}
                    clave={prod.name}
                    comments={prod.comments}
                    profile_name={prod.price}
                    currAmount={prod.amount}
                    idCategory={prod.idCategory} 
                    categorias={productos}
                    identificador={prod.id} 
                    date={prod.date}
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    // showDate={showDate}
                    setShowTransfer={setShowTransfer}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    // showComments={showComments}
                    // setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    // setComments={setComments}
                    setCategoryId={setCategoryId}
                    // transactions={transacciones}
                    withCategory={true}
                    editable={false}
                    // pendiente={false}
                    />
                  ))}
                </>
                :
                productos?.map((prod) => (
                  <TablaDisponibles
                    key={prod.id}
                    total={prod.total}
                    // date={prod.date} 
                    walletId={prod.idWallet}
                    clave={prod.name}
                    comments={prod.comments}
                    profile_name={prod.price}
                    currAmount={prod.amount}
                    idCategory={prod.idCategory} 
                    categorias={productos}
                    identificador={prod.id} 
                    date={prod.date}
                    showModify={showModifyCategory}
                    setShowModify={setShowModifyCategory}
                    setModifyId={setModifyId}
                    // showDate={showDate}
                    setShowTransfer={setShowTransfer}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    // showComments={showComments}
                    // setShowComments={setShowComments}
                    setApiUrl={setApiUrl}
                    // setComments={setComments}
                    setCategoryId={setCategoryId}
                    // transactions={transacciones}
                    withCategory={true}
                    editable={true}
                    // pendiente={false}
                  />
                ))
              }

            </ul>
            }
          </div>
        </div>
    </div>
    {showAddCategory 
      ? <AgregarProducto
          setShow={setShowAddCategory}
          show={showAddCategory} 
        /> 
      : ""
    }
    {showAddProdTransac 
      ? <AgregarProdTransac
          setShow={setShowAddProdTransac}
          show={showAddProdTransac} 
          categoryId={categoryId}
          ifShared={ifShared}
          walletForTransac={walletForTransac}
        /> 
      : ""
    }
    {showModifyCategory 
      ? <ModificarProducto
          setShow={setShowModifyCategory}
          show={showModifyCategory} 
          modifyId={modifyId}
          cantProd={cantProd}
          priceProd={priceProd}
          nameProd={nameProd}
          iconProd={iconProd}
        /> 
      : ""
    }
    
    {showConfirmation
      ? <ConfirmDelete show={showConfirmation} setShow={setShowConfirmation} identificador={categoryId} apiUrl={apiUrl} />
      : ""
    }
    {showDate
      ? <ChangeDate show={showDate} setShow={setShowDate} identificador={categoryId} />
      : ""
    }
    {showComments
      ? <AgregarComentario
          show={showComments}   
          setShow={setShowComments} 
          identificador={categoryId}  
          comments={comments}
        />
      : ""
    }
    {showTransfer
      ? <AddProduct
          show={showComments}   
          setShow={setShowTransfer} 
          identificador={categoryId} 
          categories={productos}
          sharedCategories={sharedCategorias} 
        />
      : ""
    }
    { loader 
      ? <Loading /> 
      : ""
    }
    {/* {showContra
      ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    } */}
    {window.localStorage.getItem("prevMonth") && !window.localStorage.getItem("aproved")
      ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} />
      : ""
    }
    
</>
  )
}

export default CategoriasProductos

function ConfirmDelete({show, setShow, identificador, apiUrl}) {

    const TOKEN = window.localStorage.getItem("loggedToken")
  
    async function deleteRow(){
      setShow(!show)
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/${apiUrl}del/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Deleted successfully');
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className="confirmacion_exitoso_container">
          <div className="conf_subcontainer">
              <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
              <h2>{`¿Seguro que desea eliminar este elemento?`}</h2>
              <div className='flex_conf'>
                <button className='confirm_delete' onClick={deleteRow}>Si</button>
                <button className='deny_delete' onClick={() => setShow(!show)}>No</button>
              </div>
          </div>
      </div>
    )
  }
  
  function ChangeDate({show, setShow, identificador}) {
  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const [date, setDate] = useState("")
  
    async function UpdateDate(){
      setShow(!show)
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/date/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify({date:date})
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Changed successfully');
          // console.log(response);
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
    }
  
    return (
      <div className="confirmacion_date_container">
          <div className="conf_subcontainer">
              <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
              <form onSubmit={UpdateDate}>
                <label>Nueva fecha:</label>
                {/* <span>Recuerde de usar "-" entre los números.</span> */}
                <input type="date"
                  required
                  placeholder='yyyy-mm-dd'
                  value={date}
                  onChange={(e) => {setDate(e.target.value)}}
                  // max={new Date().toISOString().split('T')[0]}
                />
                <button type='submit'>Cambiar</button>
              </form>
          </div>
      </div>
    )
  }
  
  function AddProduct({show, setShow, identificador, sharedCategories, categories}) {
  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const [transferAmount, setTransferAmount] = useState("")
    const [activeIngresos, setActiveIngresos] = useState(true);
    const [activeEgresos, setActiveEgresos] = useState(false);

    function HandleTransactionClick1() {
      setActiveIngresos(true);
      setActiveEgresos(false);
    }
    function HandleTransactionClick2() {
      setActiveIngresos(false);
      setActiveEgresos(true);
    }

    function HandleMontoChange(e){
      if(isNaN(e.target.value) && e.target.value.length < 3){
        setTransferAmount(0)
      } else {
        const inputValue = e.target.value;
        const formattedNumber = Number(inputValue.replace(/,/g,"")).toLocaleString('es-MX');
        setTransferAmount(formattedNumber)
      }
    }

    async function TransferToCat(e){
      e.preventDefault()
      // setShow(!show)
      // const PROFILE_ID = window.localStorage.getItem("profileId")      
      if(activeIngresos){
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/add/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: JSON.stringify({idProduct:Number(identificador), amount:Number(transferAmount.replace(/,/g, ""))})
          });
          if (response.status === 204 || response.status === 200) {
            // setLoader(false)
            console.log('Changed successfully');
            // console.log(response);
            // const data = await response.json();
            window.location.reload()
            // console.log(data);
          } else {
            window.location.reload()
              // console.log(response.status);
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
        } catch (error) {
          console.log(error);
        }
      }
      else if (activeEgresos) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/add/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify({idProduct:Number(identificador), amount:(Number(transferAmount.replace(/,/g, "")).toFixed(2)*(-1))})
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Changed successfully');
          // console.log(response);
          // const data = await response.json();
          window.location.reload()
          // console.log(data);
        } else {
          window.location.reload()
            // console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
      }
    }
    
    return (
      <div className="confirmacion_date_container">
          <div className="conf_subcontainer">
              <img onClick={() => setShow(false)} src={cross_exit} alt="cerrar" />
              <form onSubmit={TransferToCat}>
                {/* <span>Recuerde de usar "-" entre los números.</span> */}
                <label>Cantidad a agregar o quitar:</label>
                <div className="ing_egr">
                <p
                  onClick={HandleTransactionClick1}
                  className={activeIngresos ? "cat_egresos" : "cat_ingresos"}
                >
                Agregar
                </p>
                <p
                  onClick={HandleTransactionClick2}
                  className={activeEgresos ? "cat_egresos" : "cat_ingresos"}
                >
                Quitar
                </p>
                </div>
                <input type="text"
                  required
                  placeholder='xxxxxx'
                  value={transferAmount}
                  onChange={HandleMontoChange}
                  // max={new Date().toISOString().split('T')[0]}
                />
                <button>Guardar</button>
              </form>
          </div>
      </div>
    )
  }
  
  function IngresarContraseña({show, setShow, setAllowAction, passwordAction}) {
  
  
    const [password, setPassword] = useState("")
    const [showContraError, setShowContraError] = useState(false)
  
    const navigate = useNavigate()
  
    async function handleContraSubmit(e){
      e.preventDefault();
      const TOKEN = window.localStorage.getItem("loggedToken")
      const formCategoryData = {password:password}
      
      // console.log(formCategoryData);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pass/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data.detail);
          window.localStorage.setItem("aproved", true)
          window.location.reload()
          setAllowAction(true)
          setShow(false)
          if(passwordAction === "categorias"){
            navigate("/categorias")
          } else if (passwordAction === "automatizaciones") {          
            navigate("/automatizaciones")
          }
          } else {
            // setLoader(false)
            setShowContraError(true)
            console.log(response.status);
            setPassword("")
            
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }
  
  return (
    <div className='add_category_container'>
        <form onSubmit={handleContraSubmit}>
            <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
            <label htmlFor="contraseña">Ingrese contraseña</label>
            {showContraError
            ? <span style={{color: "#e74504", fontSize:"14px"}}>Contraseña Incorrecta</span>
            : ""
            }
            <input 
                type="password"
                id='contraseña'
                placeholder='Contraseña...'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
  }
  
import React, { useState} from "react";
import cross_exit from "../assets/cross_exit.svg";
import "../styles/AgregarCategoria.scss";

function AgregarProdTransac({walletForTransac, show, setShow, categoryId}) {
    const formatYmd = (date) => date.toISOString().slice(0, 10);

    const [cantidadProducto, setCantidadProducto] = useState(0);
    const [newComment, setNewComment] = useState("");
    const [newDate, setNewDate] = useState(formatYmd(new Date()));
    const [pendiente, /*setPendiente*/] = useState(false);

    async function handleTransacSubmit(e) {
      e.preventDefault();
      setShow(!show);
      const TOKEN = window.localStorage.getItem("loggedToken");
      if (newComment !== "") {
        const formTransacData = {
          idWallet: walletForTransac,
          idProduct:categoryId,
          amount: Number(cantidadProducto.replace(/,/g, "")).toFixed(2),
          idMonth: Number(window.localStorage.getItem("monthId")),
          name: "prueba",
          date: newDate,
          comments: [newComment],
          pendiente:pendiente
        };
        console.log(formTransacData)

        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/new/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${JSON.parse(TOKEN)}`,
              },
              withCredentials: true,
              body: JSON.stringify(formTransacData),
            }
          );

          if (response.status === 201) {
            // setLoader(false)
            window.location.reload();
            console.log("Form submitted successfully");
            // const data = await response.json();
            // console.log(data);
          } else {
            // setLoader(false)
            // setShowLoginError(true)
            window.location.reload();
            console.error("Error submitting form");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        const formTransacData = {
          idWallet: walletForTransac,
          idProduct:categoryId,
          amount: Number(cantidadProducto.replace(/,/g, "")).toFixed(2),
          idMonth: Number(window.localStorage.getItem("monthId")),
          name: "prueba",
          date: newDate,
          comments: [newComment],
          pendiente:pendiente
        };
        console.log(formTransacData);
        
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/new/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${JSON.parse(TOKEN)}`,
              },
              withCredentials: true,
              body: JSON.stringify(formTransacData),
            }
          );

          if (response.status === 201) {
            // setLoader(false)
            window.location.reload();
            console.log("Form submitted successfully");
            // const data = await response.json();
            // console.log(data);
          } else {
            // setLoader(false)
            // setShowLoginError(true)
            window.location.reload();
            console.error("Error submitting form");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  
    return (
      <div className="add_transac_container">
        <form onSubmit={handleTransacSubmit}>
          <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />   
          <h3>Realizar Registro de Salida de Inventario</h3>
          <label htmlFor="cantidadTransaccion">Cantidad</label>
          <input
            type="number"
            min={0}
            id="cantidadTransaccion"
            placeholder="xxxxxx"
            required
            value={cantidadProducto}
            onChange={(e) => {setCantidadProducto(e.target.value)}}
          />          
          <label htmlFor="fechaTransaccion">
            Fecha de transaccion (opcional)
          </label>
          <input
            type="date"
            id="fechaTransaccion"
            // min={new Date().toISOString().split("T")[0]}
            required
            value={newDate}
            onChange={(e) => {
              setNewDate(e.target.value);
            }}
          />
          <label htmlFor="comentarioTransaccion">Comentario (opcional)</label>
          <input
            type="text"
            id="comentarioTransaccion"
            placeholder="Agregar comentario..."
            value={newComment}
            onChange={(e) => {
              setNewComment(e.target.value);
            }}
          />
          {/* <label htmlFor="pendientes">Agregar a pendientes:</label>
          <input 
            className="pendiente" 
            type="checkbox" 
            value={pendiente}
            onClick={() => {
              setPendiente(!pendiente);           
            }}
          /> */}
          <button type="submit">Guardar</button>
        </form>
      </div>
    );
}

export default AgregarProdTransac
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  height: 68px;
  width: calc(100% - 280px);
  background-color: #F4F5F7;
  border-bottom: 1px solid #E8E8E8;
  margin-left: 280px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 2em;
}
header h1 {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  margin-right: 1em;
}
header .date_container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.2em;
}
header .date_container span {
  font-size: 14px;
  color: #9F9F9F;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,yBAAA;EACA,yBAAA;EACA,gCAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAAI;EACI,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAER;AAAI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;AAER;AADQ;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAGZ","sourcesContent":["header{\n    height: 68px;\n    width: calc(100% - 280px);\n    background-color: #F4F5F7;\n    border-bottom: 1px solid #E8E8E8;\n    margin-left: 280px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    padding-left: 2em;\n    h1{\n        display: inline-block;\n        font-size: 24px;\n        font-weight: 700;\n        margin-right: 1em;\n    }\n    .date_container{\n        display: flex;\n        justify-content: start;\n        align-items: center;\n        gap: .2em;\n        span{\n            font-size: 14px;\n            color: #9F9F9F;\n            font-weight: 400;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import "../styles/Header.scss"
// import arrows from "../assets/arrows_date.svg"
import {  useState, useEffect } from "react";

function Header() {

  const [profile, setProfile] = useState("")

  useEffect(() => {
    if (!window.localStorage.getItem("walletId")) {
      const PROFILE_ID = window.localStorage.getItem("userName")
      setProfile(JSON.parse(PROFILE_ID))
      
    } else {
      // const PROFILE_ID = window.localStorage.getItem("userName")
      const TOKEN = window.localStorage.getItem("loggedToken")
      const WALLET_ID = window.localStorage.getItem("walletId")
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(TOKEN)}`
      };
  
      fetch(url,{ headers, withCredentials:true })
      .then(response => response.json())
      // .then(data => console.log(data))
      .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0].name))
      // setProfile(JSON.parse(PROFILE_ID))

    }



  },[])

    // const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    // const fecha = new Date()
    // const dia = fecha.getDate();
    // const mes = fecha.getMonth() ;
    // const año = fecha.getFullYear();


  return (
    <header>
        <h1>{window.localStorage.getItem("walletId")? "Visualizando":"Bienvenido"} "{profile}"</h1>
        <div className="date_container">
            {/* <img src={arrows} alt="flechas" />
            <span>{meses[mes]} {dia}, {año}</span> */}
        </div>
    </header>
  )
}

export default Header
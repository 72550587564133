import React, { useState } from 'react'
import "../styles/AgregarCategoria.scss"
import cross_exit from "../assets/cross_exit.svg"

function ModificarProducto({show, setShow, modifyId}) {

    const [newCategoryName, setNewCategoryName] = useState("")    
    const [newPrice, setNewPrice] = useState(0)    
    const [newAmount, setNewAmount] = useState(0)    

    async function handleCategorySubmit(e){
      e.preventDefault();
      setShow(!show)
      const TOKEN = window.localStorage.getItem("loggedToken")
      const newName = {name:newCategoryName, price:newPrice, amount:newAmount}

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/update/${modifyId}/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(newName)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data);
          window.location.reload()
          } else {
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }

  return (
    <div className='add_category_container'>
        <form onSubmit={handleCategorySubmit}>
            <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
            <label htmlFor="nuevaCategoria">Renombrar Producto</label>
            <input 
                type="text"
                id='nuevaCategoria'
                placeholder='Renombrar producto...'
                required
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
            />
            <label htmlFor="nuevoPrecio">Nuevo Precio</label>
            <input 
                type="number"
                id='nuevoPrecio'
                placeholder='xxxxxx'
                required
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
            />
            <label htmlFor="nuevaCant">Nueva Cantidad</label>
            <input 
                type="number"
                id='nuevaCant'
                placeholder='xxxxxx'
                required
                value={newAmount}
                onChange={(e) => setNewAmount(e.target.value)}
            />
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
}

export default ModificarProducto